import React from 'react';
import { ObservationResult } from '../Types.ts';

const colorInRange = 'green';
const colorOutRange = 'red';
const colorOutOfScope = 'lightgray';
const colorSeparator = 'darkgray';
const colorCircleBig = 'rgba(255,255,255,.8)';
const colorCircleSmall = 'black';

const vwidth = 100.0;
const vheight = vwidth / 16.0;

const outerPadding = (vheight / 3.0) * 2.0;
const separatorPadding = vheight / 4.0;
const separatorThickness = vheight / 12.0;

const diameterBig = vheight;
const diameterSmall = vheight / 2.0;
const rBig = diameterBig / 2.0;
const rSmall = diameterSmall / 2.0;

const innerWidth = vwidth - 2 * outerPadding;
const segWidth = innerWidth / 4.0;

const x1 = outerPadding;
const x2 = x1 + segWidth;
const x3 = x2 + segWidth;
const x4 = x3 + segWidth;
const x5 = x4 + segWidth;
const yc = vheight / 2.0;

const toFloat = (value: string | null, safe: boolean): number | null => {
  return !value || Number.isNaN(value) ? (safe ? 0 : null) : Number.parseFloat(value!);
};

const Bar: React.FC<{
  x1: number;
  x2: number;
  yc: number;
  radius: number;
  color: string;
}> = ({ x1, x2, yc, radius, color }) => {
  return <rect x={x1} y={yc - radius} width={x2 - x1} height={2 * radius} rx={radius} fill={color} />;
};

export const RangeRenderer: React.FC<{
  result: ObservationResult;
  width?: number | string | undefined;
  height?: number | string | undefined;
  stretch?: boolean;
}> = ({ result, width, height, stretch }) => {
  // if result.value not number -> return

  const measureValue = toFloat(result.measuredValue, false);
  if (!measureValue) {
    return null;
  }
  const minValue = toFloat(result.rangeFrom, true)!;
  const maxValue = toFloat(result.rangeTo, true)!;
  const range = Math.abs(maxValue - minValue);
  if ((minValue === 0 && maxValue === 0) || range === 0 || Number.isNaN(range)) {
    return null;
  }

  const isFrom = !minValue;
  const greenRangeWidth = isFrom ? 3.0 * segWidth : 2.0 * segWidth;
  const startGreen = isFrom ? 0.0 : segWidth;
  const start = startGreen + (greenRangeWidth / range) * (measureValue - minValue);
  const calcDot = Math.min(Math.max(0.0, start), innerWidth);

  return (
    <svg
      viewBox={`0 0 ${vwidth} ${vheight}`}
      width={width}
      height={height}
      preserveAspectRatio={stretch ? 'none' : undefined}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1={x4} y1={yc - rBig} x2={x4} y2={yc + rBig} strokeWidth={separatorThickness} stroke={colorSeparator} />
      {isFrom ? (
        <Bar
          x1={x1}
          x2={x4 - separatorPadding}
          yc={yc}
          radius={measureValue > maxValue ? rSmall : rBig}
          color={measureValue > maxValue ? colorOutOfScope : colorInRange}
        />
      ) : (
        <>
          <line
            x1={x2}
            y1={yc - rBig}
            x2={x2}
            y2={yc + rBig}
            strokeWidth={separatorThickness}
            stroke={colorSeparator}
          />
          <Bar
            x1={x1}
            x2={x2 - separatorPadding}
            yc={yc}
            radius={measureValue < minValue ? rBig : rSmall}
            color={measureValue < minValue ? colorOutRange : colorOutOfScope}
          />
          <Bar
            x1={x2 + separatorPadding}
            x2={x4 - separatorPadding}
            yc={yc}
            radius={measureValue < minValue || measureValue > maxValue ? rSmall : rBig}
            color={measureValue < minValue || measureValue > maxValue ? colorOutOfScope : colorInRange}
          />
        </>
      )}
      <Bar
        x1={x4 + separatorPadding}
        x2={x5}
        yc={yc}
        radius={measureValue > maxValue ? rBig : rSmall}
        color={measureValue > maxValue ? colorOutRange : colorOutOfScope}
      />
      <circle cx={x1 + calcDot} cy={yc} r={rBig} fill={colorCircleBig} />
      <circle cx={x1 + calcDot} cy={yc} r={rSmall} fill={colorCircleSmall} />
    </svg>
  );
};
