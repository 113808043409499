import React from 'react';
import { OrderResult } from '../Types.ts';

export const SpecimenRenderer: React.FC<{ result: OrderResult }> = ({ result }) => {
  const specimen = result.observationSpecimen;

  if (!specimen) {
    return null;
  }
  // TODO WIP

  return (
    <>
      <h2>Probenherkunft</h2>
      <div>
        <small>{specimen.resultDistributionCategory}</small> {specimen.testName} {specimen.value}
      </div>
    </>
  );
};
