/*
            3f.mmAsPt(), // accreditation
            0f, // testname
            60f.mmAsPt(), // testname, comment start
            6f.mmAsPt(), // pathological
            18f.mmAsPt(), // value
            15f.mmAsPt(), // unit
            21f.mmAsPt(), // range
            37f.mmAsPt(), // graphic

 */

import { css } from '@emotion/css';
import { GlobalToken } from 'antd';

const color = (isPathological: boolean) => (isPathological ? 'red' : 'inherit');
const fontWeight = (isPathological: boolean) => (isPathological ? 'bold' : 'inherit');

export const headRow = (token: GlobalToken) => css`
  border-bottom: 1px solid ${token.colorBorder};

  & th {
    padding: 0 0 ${token.padding}px 0;
    color: ${token.colorTextSecondary};
    font-weight: normal;
    text-align: left;
    text-transform: uppercase;
  }
`;

export const valueRow = (token: GlobalToken, isPathological: boolean, hasComents: boolean) => css`
  ${hasComents ? '' : `border-bottom: 1px dotted ${token.colorBorder};`}
  & td {
    padding: ${token.padding}px 0 ${token.padding}px 0;
    color: ${color(isPathological)};
    font-weight: ${fontWeight(isPathological)};
  }
`;

export const commentRow = (token: GlobalToken) => css`
  border-bottom: 1px dotted ${token.colorBorder};
`;
