import React from 'react';
import { ObservationResult, OrderResult } from '../Types';
import { ObservationResultRenderer } from './ObservationResultRenderer';
import { Filter } from '../filter/FilterTypes';
import { headRow } from './ObservationResultTable.styles';
import { theme } from 'antd';
import { css } from '@emotion/css';

type Group = {
  groupName: string;
  results: ObservationResult[];
};

function matchFilter(result: ObservationResult, filter: Filter) {
  if (filter.pathological !== null && (result.pathological || '').length > 0 !== filter.pathological) {
    return false;
  }
  return true;
}

/**
 * Intentionally no hash grouping used so that the order of the results is preserved,
 * regardless of whether a group can occur more than once
 */
const group = (results: ObservationResult[], filter: Filter): Group[] => {
  const groups = [];

  let lastGroup: Group | null = null;
  for (const result of results) {
    if (matchFilter(result, filter)) {
      if (lastGroup === null || lastGroup!.groupName !== result.resultGroup) {
        lastGroup = { groupName: result.resultGroup || '', results: [result] };
        groups.push(lastGroup);
      } else {
        lastGroup.results.push(result);
      }
    }
  }

  return groups;
};

// TODO WIP
export const ObservationResultsRenderer: React.FC<{ result: OrderResult; filter: Filter }> = ({ result, filter }) => {
  const { token } = theme.useToken();
  // TODO better state handling
  const groups = group(result.observationResults, filter);

  if (groups.length === 0) {
    // TODO
    return <>no match</>;
  }

  return groups.map((group, index) => {
    return (
      <div
        key={`${group.groupName}${index}`}
        className={css`
          & .accreditation-cell {
            font-size: small;
            width: 20px;
          }
          & .parameter-cell {
            width: auto;
            min-width: 200px;
          }
          & .pathological-cell {
            width: 32px;
          }
          & .value-cell {
            width: 200px;
          }
          & .unit-cell {
            width: 200px;
          }
          & .range-cell {
            width: 200px;
          }
          & .graphic-cell {
            width: 200px;
          }
          & .comment-cell {
            position: relative;
            & span {
              position: absolute;
              top: 2px;
              color: ${token.colorInfo};
            }
            & pre {
              color: ${token.colorInfo};
              margin-left: 24px;
              font-size: 12px;
              white-space: pre-wrap;
            }
          }
          @media (max-width: ${token.screenLG}px) {
            & .unit-cell {
              display: none;
            }
          }
          @media (max-width: 700px) {
            & .parameter-cell {
              width: auto;
              min-width: unset;
            }
            & .unit-cell {
              display: none;
            }
            & .graphic-cell {
              display: none;
            }
          }
        `}
      >
        <h2>{group.groupName}</h2>
        <table>
          <thead>
            <tr className={headRow(token)}>
              <th className="accreditation-cell"></th>
              <th className="parameter-cell">Untersuchung</th>
              <th className="pathological-cell"></th>
              <th className="value-cell">Ergebnis</th>
              <th className="unit-cell"></th>
              <th className="range-cell">Normbereich</th>
              <th className="graphic-cell"></th>
            </tr>
          </thead>
          <tbody>
            {group.results.map((result, index) => (
              <ObservationResultRenderer key={`${result.testName}${index}`} result={result} />
            ))}
          </tbody>
        </table>
      </div>
    );
  });
};
