import React from 'react';
import { ObservationResult } from '../Types';
import { commentRow, valueRow } from './ObservationResultTable.styles';
import { RangeRenderer } from './RangeRenderer';
import { theme } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// TODO WIP

export const ObservationResultRenderer: React.FC<{ result: ObservationResult }> = ({ result }) => {
  const { token } = theme.useToken();
  const isPathological = !!result.pathological;
  const hasComments = result.comment.length > 0;
  return (
    <>
      <tr className={valueRow(token, isPathological, hasComments)}>
        <td className="accreditation-cell">{result.accreditation}</td>
        <td className="parameter-cell">{result.testName}</td>
        <td className="pathological-cell">{result.pathological}</td>
        <td className="value-cell">{result.measuredValue}</td>
        <td className="unit-cell">{result.unit}</td>
        <td className="range-cell">{result.standardRange}</td>
        <td className="graphic-cell">
          <RangeRenderer result={result} width={'200px'} height={'12px'} />
        </td>
      </tr>
      {hasComments ? (
        <tr className={commentRow(token)}>
          <td></td>
          <td colSpan={6} className="comment-cell">
            <InfoCircleOutlined />
            <pre>{result.comment.join('\n')}</pre>
          </td>
        </tr>
      ) : null}
    </>
  );
};
