import React from 'react';
import { OrderResult } from '../Types.ts';

export const RequestRenderer: React.FC<{ result: OrderResult }> = ({ result }) => {
  // TODO WIP
  return (
    <>
      Patient: {result.patient.svnr} / {result.patient.firstName} {result.patient.lastName}
    </>
  );
};
