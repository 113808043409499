import { Flex, theme } from 'antd';
import React from 'react';
import { ObservationResultsRenderer } from './observationresult/ObservationResultsRenderer.tsx';
import { OrderResult } from './Types.ts';
import { RequestRenderer } from './request/RequestRenderer.tsx';
import { SpecimenRenderer } from './specimen/SpecimenRenderer.tsx';

// TODO format date/time

export const OrderResultRenderer: React.FC<{ result: OrderResult }> = ({ result }) => {
  const { token } = theme.useToken();
  // TODO WIP Filter, ....
  return (
    <Flex vertical gap={`${token.margin}px`}>
      <RequestRenderer result={result} />
      <SpecimenRenderer result={result} />
      <ObservationResultsRenderer result={result} filter={{ pathological: null }} />
    </Flex>
  );
};
