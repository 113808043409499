import React from 'react';
import { css } from '@emotion/css';
import { Button, Empty, theme } from 'antd';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { sample } from './befundsample.ts';
import { OrderResultRenderer } from '../orderresult/OrderResultRenderer.tsx';

export const BefundDetails: React.FC<{ selectedId: string | undefined }> = ({ selectedId }) => {
  const { token } = theme.useToken();

  return (
    <div
      className={css`
        height: 100%;
        overflow-y: auto;
        padding-left: ${token.paddingXL}px;
        flex-grow: 1;

        @media (max-width: ${token.screenLG}px) {
          height: calc(100dvh - 80px);
          position: absolute;
          left: 0;
          right: 0;
          padding: 0 ${token.paddingLG}px 0 ${token.paddingLG}px;
          transform: translateX(${selectedId ? 0 : '100vw'});
          transition: transform 0.4s ease-in-out;

          background-color: ${token.Layout?.bodyBg};
          z-index: 10;
        }
      `}
    >
      {!selectedId ? (
        <Empty
          className={css`
            flex-grow: 1;

            @media (max-width: ${token.screenLG}px) {
              display: none;
            }
          `}
          description="Bitte wählen Sie einen Befund aus"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      ) : (
        <>
          <Link
            to="/"
            className={css`
              @media (min-width: ${token.screenLG}px) {
                display: none;
              }
            `}
          >
            <Button icon={<LeftOutlined />}>Zurück zur Übersicht</Button>
          </Link>
          <h1
            className={css`
              line-height: 1.1em;

              @media (max-width: ${token.screenLG}px) {
                margin-top: ${token.marginMD}px;
              }
            `}
          >
            Mag. Max Mustermann
          </h1>
          <OrderResultRenderer result={sample} />
        </>
      )}
    </div>
  );
};
